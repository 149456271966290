/* eslint-disable no-console, consistent-return*/
import CircuitBreaker from 'opossum';

const breakers = {};
const self = {};

self.fallBackResponse = (fallback) => {
    if (typeof fallback === 'function') {
        return fallback();
    }

    if (typeof fallback === 'object' && fallback !== null) {
        return fallback;
    }
};

self.handleFallback = (name, options) => {
    if (options.retry?.attempts && options.retry.attempts !== 0) {
        return { status: 'FALLBACK' };
    } if (options.onFallback) {
        console.log(`Returning fallback for ${name}`);
        return self.fallBackResponse(options.onFallback);
    }
};

self.fireCircuitBreaker = async (name, circuitBreaker, args, options) => {
    let result;
    const retry = options?.retry?.attempts || 0;
    result = await circuitBreaker.fire(...args).catch(
        (e) => {
            console.log(e);
            return self.handleFallback(name, options);
        }
    );
    if (result?.status === 'FALLBACK' && retry !== 0) {
        console.log(`Retrying ${name}... ${options.retry.attempts} attempts left, waiting ${options.retry.timeout}`);
        setTimeout(async () => {
            options.retry.attempts -= 1;
            result = await self.fireCircuitBreaker(name, circuitBreaker, args, options);
        }, options.retry.timeout);
    } else {
        return result;
    }
};

self.addAdditionalOnHandleres = (circuitBreaker, options) => {
    const additionalEventHandlers = options?.additionalOptions?.additionalEventHandlers;
    if (additionalEventHandlers?.flipper && additionalEventHandlers?.events) {
        additionalEventHandlers.events.forEach((eventHandler) => {
            try {
                if (eventHandler.event !== undefined && typeof eventHandler.handler === 'function') {
                    circuitBreaker.on(eventHandler.event, eventHandler.handler);
                }
            } catch (error) {
                console.log(`Error: Adding CircuitBreaker eventHandler ${eventHandler.name}`, error);
            }
        });
    }
};

self.createCircuitBreaker = (name, request, options) => {
    const circuitBreakerOptions = options?.circuitBreakerOptions || {};
    const additionalOptions = options?.additionalOptions || {};
    const customCircuitBreakerOptions = {
        timeout: circuitBreakerOptions?.timeout || 1000,
        errorThresholdPercentage: circuitBreakerOptions?.errorThresholdPercentage || 10,
        resetTimeout: circuitBreakerOptions?.resetTimeout || 60000,
        ...circuitBreakerOptions,
    };
    const circuitBreaker = new CircuitBreaker(request, customCircuitBreakerOptions);

    circuitBreaker.on('success', (data) => data);

    circuitBreaker.fallback(() => self.handleFallback(name, additionalOptions)); // called before fallback event, what is returned here is passed to fallback event and is returned by fire()

    circuitBreaker.on('failure', (data) => { // triggered when promise is rejected, request fails or timeout reached
        console.log(`${name} failed.`, data.message);
    });

    circuitBreaker.on('timeout', () => { // triggered if request does not resolve/finish before timeout set in config
        console.log('The request has exceeded it\'s timeout threshold');
    });

    self.addAdditionalOnHandleres(circuitBreaker, options);

    return circuitBreaker;
};

self.getCircuitBreaker = (key, name, request, options) => {
    // Check if a circuit breaker has already been established for this path
    // and return if it has.
    let circuitBreaker;
    const eventsFlipper = options?.additionalOptions?.additionalEventHandlers?.flipper;
    const cachekey = (eventsFlipper) ? `${name}|${key}|events=${eventsFlipper}` : `${name}|${key}`;

    if (breakers[cachekey]) {
        circuitBreaker = breakers[cachekey];
    } else {
        circuitBreaker = self.createCircuitBreaker(name, request, options);
        breakers[cachekey] = circuitBreaker;
    }

    return circuitBreaker;
};

self.withCircuitBreaker = async (name, request, args, options) => {
    const additionalOptions = options?.additionalOptions || {};
    const circuitBreaker = additionalOptions.key
        ? self.getCircuitBreaker(additionalOptions.key, name, request, options)
        : self.createCircuitBreaker(name, request, options);
    return self.fireCircuitBreaker(name, circuitBreaker, args, additionalOptions);
};

export default {
    _self: self,
    withCircuitBreaker: self.withCircuitBreaker,
    getCircuitBreaker: self.getCircuitBreaker,
    createCircuitBreaker: self.createCircuitBreaker,
    fireCircuitBreaker: self.fireCircuitBreaker,
};
