import { fetchJSON } from '@bonnet/core/fetch';

export default async (query) => fetchJSON('/collections/ccServices/rest/ccs/sponsoredmake', {
    query,
    circuitBreaker: {
        id: 'sponsoredMake',
        timeout: process.env.CIRCUIT_BREAKER_TIMEOUT,
        fallback: {},
    },
});
