import { fetchJSON } from '@bonnet/core/fetch';

export default (pixAllId, debug) => {
    const options = {
        circuitBreaker: {
            id: 'cbh',
            timeout: 1000,
            resetTimeout: 300000,
            fallback: {},
        },
    };

    const endpoint = '/collections/cbh/history/' + pixAllId;
    const queryString = '?pagetypefilter=search' + (debug ? '&debug=true' : '');
    return fetchJSON(endpoint + queryString, options);
};
