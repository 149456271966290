/* eslint-disable no-console */
import createLoader from './loader';
import request from './fetch';

export { default as cache } from './cache';
export { default as createLoader } from './loader';
export { default as request } from './fetch';

export const fetchLoader = ({ name, url, params, ssr = true }) => createLoader({
    name,
    functionToCache: async () => {
        const response = await request(url, {
            method: 'get',
            params,
            ssr,
        });
        return {
            data: response.data,
            status: response.status,
            params,
        };
    },
});
