/* eslint-disable no-console */
import {
    page,
    inventoryClick,
    inventoryImpressions,
    inventoryCarouselPagination,
    inventorySave,
    inventorySearch,
    ownerLogoClick,
    researchArticleClick,
    researchBtnCTAClick,
    styleReviewsLinkClick,
    searchSubmitClick,
    advancedSearchClick,
    browsByMakeClick,
    browsByStyleClick,
    browsByBudgetClick,
    makeDropDownClick,
    modelDropDownClick,
    carBravoCTAClick,
    cpoLearnMoreClick,
    cpoSearchClick,
    customOrderByMakeImpression,
    customOrderByMakeClick,
    customOrderSeeMoreClick,
    deepLinkClick,
    findModelsByMakeClick,
    getPayoffQuoteFromLenderClick,
    getPayoffQuoteSubmitClick,
    mostSearchClick,
    myWalletSearchByBudgetClick,
    walletAutoSyncSuccess,
    recentSearchClick,
    recentSearchImpression,
    searchNearYouClick,
    searchByMakeClick,
    sellingYourCarCTAClick,
    productTileClick,
    featuredPartnerImpression,
    featuredPartnerClick,
    kbbLogoClick,
    myWalletGetStartedClick,
    browseByBudgetWalletContinueClick,
    makeExampleLinkClick,
    modelExampleLinkClick,
    googleAssistantClick,
    amazonAlexaClick,
    cpoOffsiteClick,
    marketValueCTAClick,
    marketValueLINKClick,
    ownerImpressions,
    homeServicesCTAClick,
    homeServicesMediaEvent,
    heroAdCTAClick,
    zipOnChange,
    seoLinkClick,
    footerLinkClick,
    headerGeneralEventHandler,
    carAlertsClick,
    icoInlineTextLinkClick,
    setMyBudgetClick,
    setBudgetSearchFilter,
    editBudgetSideModule,
    shopWithBudgetSideModule,
    mobileSmartBannerCTAClick,
    mobileSmartBannerImpression,
    videoMediaEvent,
    // Accelerate/SDP Analytics Handlers
    // Do not remove those imports since it's being use to popular tags for relevant pages
    creditAppSubmit,
    ownerWebsite,
    myWalletOpenPageLoad,
    myWalletSummaryLinkClick,
    myWalletInteractionClick,
    walletImportSettingsClick,
    verifyCreditRangeClick,
    myWalletSearchAvailableVehiclesSubmit,
    myWalletSellYourCarClick,
    myWalletInputChange,
    myWalletTradeInClickLink,
    myWalletTradeInSubmit,
    myWalletKbbTradeInIndicator,
    myDealSeeDetailsClick,
    seeDetailsMyWalletDealClick,
    offsiteBrowserPushNotificationImpression,
    offsiteBrowserPushNotificationClick,
    paymentOptionClick,
    signUpCtaClick,
    findThatCarCtaClick,
    messagesHeaderLinkClick,
    headerLittleGuyClick,
} from 'reaxl-analytics';

import _get from 'lodash.get';
import {
    shopCarsClick,
    shopCpoClick,
    additionalVehicleCategoriesClick,
} from './srpSearchCTAHandlers';

import { userDuck, ownersDuck, paymentsDuck } from '../../ducks';

import mainSeoDeepLinkClick from './deepLinkHandlers';

export default {
    // Standard page event callback
    page,
    keywordSearchSubmit: (metadata, option) => {
        const keywordPhrases = _get(metadata, 'data.codes.keywordPhrases', null) || _get(metadata, 'data.name', '');
        return inventorySearch({
            ...metadata,
            data: {
                ...metadata.data,
                isKeywordSearch: true,
                keywordPhrases,
            },
        }, option);
    },
    inventoryClick: (metadata, option) => inventoryClick({
        ...metadata,
        userDuck,
    }, option),
    eLotTileClick: (metadata, option) => ownerLogoClick({
        ...metadata,
        data: {
            ...metadata.data,
            pixallData: {
                isCarousel: false,
            },
        },
        userDuck,
    }, option),
    inventoryArrowClick: (metadata, option) => inventoryCarouselPagination({
        ...metadata,
        data: {
            par: 'dl_v_lstg_1',
            listingType: 'v_dl',
            ...metadata.data,
        },
    }, option),
    inventoryImpressions: (metadata, option) => inventoryImpressions({
        ...metadata,
        userDuck,
    }, option),
    inventorySave: (metadata, option) => inventorySave({
        ...metadata,
        userDuck,
    }, option),
    myWalletSearchByBudgetClick,
    myWalletSellYourCarClick: (metadata, option) => myWalletSellYourCarClick({
        ...metadata,
        paymentsDuck,
    }, option),
    walletAutoSyncSuccess,
    productTileClick: (metadata, option) => productTileClick({
        ...metadata,
        userDuck,
    }, option),
    researchArticleClick,
    researchBtnCTAClick,
    styleReviewsLinkClick,
    searchSubmitClick,
    advancedSearchClick,
    browsByMakeClick,
    browsByStyleClick,
    browsByBudgetClick,
    makeDropDownClick,
    modelDropDownClick,
    featuredPartnerImpression,
    featuredPartnerClick,
    kbbLogoClick,
    carBravoCTAClick,
    cpoLearnMoreClick,
    cpoSearchClick,
    customOrderByMakeImpression,
    customOrderByMakeClick,
    customOrderSeeMoreClick,
    deepLinkClick,
    findModelsByMakeClick,
    getPayoffQuoteFromLenderClick,
    getPayoffQuoteSubmitClick,
    mostSearchClick,
    recentSearchClick,
    recentSearchImpression,
    searchNearYouClick,
    searchByMakeClick,
    myWalletGetStartedClick: (metadata, option) => myWalletGetStartedClick({
        ...metadata,
        userDuck,
    }, option),
    browseByBudgetWalletContinueClick,
    makeExampleLinkClick,
    modelExampleLinkClick,
    googleAssistantClick,
    amazonAlexaClick,
    cpoOffsiteClick,
    marketValueCTAClick,
    marketValueLINKClick,
    ownerImpressions: (metadata, option) => ownerImpressions({
        ...metadata,
        userDuck,
        ownersDuck,
    }, option),
    heroAdCTAClick,
    zipOnChange,
    homeServicesCTAClick,
    homeServicesMediaEvent,
    seoLinkClick,
    footerLinkClick,
    headerGeneralEventHandler,
    carAlertsClick: (metadata, ...arg) => carAlertsClick({
        ...metadata,
    }, ...arg),
    icoInlineTextLinkClick,
    setMyBudgetClick,
    setBudgetSearchFilter,
    editBudgetSideModule,
    shopWithBudgetSideModule,
    mobileSmartBannerCTAClick,
    mobileSmartBannerImpression,
    videoMediaEvent,
    // Accelerate/SDP Analytics Handlers
    creditAppSubmit,
    ownerWebsite,
    myWalletOpenPageLoad,
    myWalletSummaryLinkClick,
    myWalletInteractionClick: (metadata, option) => myWalletInteractionClick({ ...metadata, paymentsDuck }, option),
    walletImportSettingsClick,
    verifyCreditRangeClick,
    myWalletSearchAvailableVehiclesSubmit,
    myWalletInputChange,
    myWalletTradeInClickLink,
    myWalletTradeInSubmit,
    myWalletKbbTradeInIndicator,
    paymentOptionClick,
    myDealSeeDetailsClick,
    seeDetailsMyWalletDealClick,
    offsiteBrowserPushNotificationImpression,
    offsiteBrowserPushNotificationClick,
    signUpCtaClick,
    sellingYourCarCTAClick,
    findThatCarCtaClick,
    messagesHeaderLinkClick,
    headerLittleGuyClick,
    shopCarsClick,
    shopCpoClick,
    additionalVehicleCategoriesClick,
    mainSeoDeepLinkClick,
};
