import PersonalizationEngineModuleCreator from '@atc/bonnet-module-creator-personalization-engine';

const PersonalizationEngineModule = PersonalizationEngineModuleCreator({
    getApiQuery: (ctx) => ({
        bu: 'atc',
        app: 'web',
        catalog: 'atc',
        includeRaw: true,
        includeSources: 'consumerAdTargets,activeExperiments,consumerCampaignPreferences,consumerInsights,consumerAdTargetsWithoutCAMPBias,consumerMarketingSegments',
    }),
});

export default PersonalizationEngineModule;
