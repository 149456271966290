import { asyncRoute, addRoutes } from '@bonnet/core/router';
import styleCodesRoutes from './reference/styleCodesRoutes';
import vehicleMakes from './reference/vehicleMakes';
import { getMMTRoutes, getYearRoutes } from './utilities/getRoutes';

const styleNames = Object.keys(styleCodesRoutes).join('|');
const mmtRoutes = `/:make(${getMMTRoutes(vehicleMakes)})`;
const currentYear = new Date().getFullYear();

// MM, YMM, YMMT pages have been redirected
// to their respective /cars-for-sale page
const routes = addRoutes([
    {
        path: '/',
        exact: true,
        component: asyncRoute(() => import(/* webpackChunkName: "homepage" */'./pages/HomePage')),
    },
    {
        path: [
            '/:listingTypes(certified|new|used)-cars',
        ],
        exact: true,
        component: asyncRoute(() => import(/* webpackChunkName: "landing" */'./pages/LandingPage')),
    },
    {
        path: [
            '/cars-for-sale/',
        ],
        exact: true,
        component: asyncRoute(() => import(/* webpackChunkName: "landing" */'./pages/LandingPage')),
    },
    {
        path: async () => [
            `${mmtRoutes}/:year(${getYearRoutes(currentYear)})?`.toLowerCase(),
        ],
        exact: true,
        component: asyncRoute(() => import(/* webpackChunkName: "make" */'./pages/MakeCollectionPage')),
    },
    {
        path: [
            `/:vehicleStyle(${styleNames})`,
        ],
        exact: true,
        component: asyncRoute(() => import(/* webpackChunkName: "style" */'./pages/StyleCollectionPage')),
    },
    {
        path: [
            '/legacy/research/modelinfo/details.xhtml',
        ],
        exact: true,
        component: asyncRoute(() => import(/* webpackChunkName: "legacyRedir" */'./pages/LegacyRedirectionPage')),
    },
    {
        path: [
            '/how-to-buy-cars-online',
        ],
        exact: true,
        component: asyncRoute(() => import(/* webpackChunkName: "howToBuyCars" */'./pages/HowToBuyCarsPage')),
    },
    {
        path: [
            '/car-subscription-services',
        ],
        exact: true,
        component: asyncRoute(() => import(/* webpackChunkName: "carSub" */'./pages/CarSubscriptionPage')),
    },
    {
        path: [
            '/request-privacy',
        ],
        exact: true,
        component: asyncRoute(() => import(/* webpackChunkName: "requestPrivacy" */'./pages/RequestPrivacyPage')),
    },
    {
        path: [
            '/car-:calcType(payment|affordability|lease)-calculator',
        ],
        exact: true,
        component: asyncRoute(() => import(/* webpackChunkName: "calculator" */'./pages/CalculatorPage')),
    },
    {
        path: [
            '/seeitfindit',
        ],
        exact: true,
        component: asyncRoute(() => import(/* webpackChunkName: "atHomeServicesCampaign" */'./pages/AtHomeServicesPage')),
    },
    {
        path: [
            '/fyusion',
        ],
        exact: true,
        component: asyncRoute(() => import(/* webpackChunkName: "fyusion" */'./pages/FyusionPage')),
    },
    {
        path: [
            '/email-us',
        ],
        exact: true,
        component: asyncRoute(() => import(/* webpackChunkName: "help" */'./pages/EmailUsPage')),
    },
]);

export default routes;
