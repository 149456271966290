import { objectDuckCreator } from '@atc/modular-redux';

const interestRateDuck = objectDuckCreator({
    store: 'interestRates',
    initialState: {},
}).extend({
    reducer: (state, action, { types }) => {
        switch (action.type) {

            default:
                return state;
        }
    },
});

interestRateDuck.buildInitialState = ({ interestRates }) => ({
    ...interestRates,
});

export default interestRateDuck;
