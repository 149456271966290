import { objectDuckCreator } from '@atc/modular-redux';

export default objectDuckCreator({
    store: 'pageDetails',
    initialState: {
        siteSection: '',
        pageType: '',
        detailPageName: '',
        pixallPageInstanceId: '',
    },
});
