import { fetchJSON } from '@bonnet/core/fetch';

export default function fetchDealerLot(criteria) {
    return fetchJSON('/collections/lcServices/rest/lsc/dealerlot/single', {
        query: criteria,
        circuitBreaker: {
            id: 'dealerLotSingle',
            timeout: 1000,
            fallback: {},
        },
    });
}
