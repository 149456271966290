import { useEffect } from 'react';
import { useOptimizelyTrack } from 'reaxl-optimizely';

// Map Optimizely event IDs to event value.
const eventValues = {

};

// Map analytics event IDs (BIRF cmp IDs) to Optimizely event IDs
const matchEvent = () => ({
    spec_v: 'hp_to_srp_conversion',
    rcnt_srch_v: 'hp_to_srp_conversion',
    srch_avbl_v: 'hp_to_srp_conversion',
});

export const setUpListener = (optimizelyTrack) => () => {
    const optimizelyTrackWithValue = (apiName) => {
        const value = eventValues[apiName];
        optimizelyTrack(apiName, {
            value,
        }); // eslint-disable-next-line no-console
        console.info(`[Optimizely] Event Fired: ${apiName}.`);

        if (value) { // eslint-disable-next-line no-console
            console.info(`[Optimizely] Event Fired: ${apiName} total_value: ${value}.`);
            optimizelyTrack('total_value', {
                value,
            });
        }
    };

    const {
        location: {
            pathname,
        },
    } = global;

    if (pathname === '/') {
        optimizelyTrackWithValue('hp_pageview');
    }

    const optimizelyAnalyticsClickEventListener = (event) => {
        const cmp = event.detail.cmp || event.detail.data.cmp;
        const apiNameOrNameList = matchEvent()[cmp];

        if (apiNameOrNameList) {
            const apiNameList = apiNameOrNameList instanceof Array ? apiNameOrNameList : [apiNameOrNameList];
            apiNameList.forEach(optimizelyTrackWithValue);
        }
    };

    global.addEventListener('analytics:click', optimizelyAnalyticsClickEventListener);
    return () => {
        global.removeEventListener('analytics:click', optimizelyAnalyticsClickEventListener);
    };
};

const OptimizelyAnalyticsListenerContainer = () => {
    const optimizelyTrack = useOptimizelyTrack();

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(setUpListener(optimizelyTrack), []);

    return null;
};

export default OptimizelyAnalyticsListenerContainer;
