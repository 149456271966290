import { objectDuckCreator } from '@atc/modular-redux';

export default objectDuckCreator({
    namespace: 'KEYWORDSEARCH',
    storePath: 'keywordSearch',
    store: 'content',
    initialState: {
        showModal: false,
        filters: {
            searchRadius: {
                label: 'Search Radius',
                name: 'searchRadius',
                options: [
                    {
                        label: '10 Miles',
                        value: '10',
                    },
                    {
                        label: '25 Miles',
                        value: '25',
                    },
                    {
                        label: '50 Miles',
                        selected: true,
                        value: '50',
                    },
                    {
                        label: '75 Miles',
                        value: '75',
                    },
                    {
                        label: '100 Miles',
                        value: '100',
                    },
                    {
                        label: '200 Miles',
                        value: '200',
                    },
                    {
                        label: '300 Miles',
                        value: '300',
                    },
                    {
                        label: '400 Miles',
                        value: '400',
                    },
                    {
                        label: '500 Miles',
                        value: '500',
                    },
                    {
                        label: 'Any Distance',
                        value: '0',
                    },
                ],
            },
            zip: {
                label: 'ZIP Code',
                name: 'zip',
            },
        },
        searchRadius: '50',
        errors: {},
    },
});
