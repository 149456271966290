/* eslint-disable no-console */
import { fetchJSON } from '@bonnet/core/fetch';

export default () => fetchJSON('/collections/wp/cs-camp/wp-json/wp/v2/at_marketing_value/?slug=marketing-value-prop', {
    circuitBreaker: {
        id: 'atMarketingValue',
        timeout: process.env.CIRCUIT_BREAKER_TIMEOUT,
        fallback: {},
    },
});

// https://staging.kbb.com/cs-camp/wp-json/wp/v2/at_marketing_value/?slug=6283343
// wp/cs-camp/wp-json/wp/v2/at_marketing_value/?slug=marketing-value-prop
// https://staging.kbb.com/cs-camp/wp-json/wp/v2/at_marketing_value/?slug=6280
