/* eslint-disable import/prefer-default-export,import/extensions */
// @create-index
import addMocks from '../mocks';
import _fetchAemContent from './fetchAemContent.js';
import _fetchHomePageBannerContent from './fetchHomePageBannerContent.js';
import _fetchArticles from './fetchArticles.js';
import _fetchConfigs from './fetchConfigs.js';
import _fetchDataIsland from './fetchDataIsland.js';
import _fetchDealerLot from './fetchDealerLot.js';
import _fetchDealerLotSingle from './fetchDealerLotSingle.js';
import _fetchDeepLinks from './fetchDeepLinks.js';
import _fetchIco from './fetchIco.js';
import _fetchInterestRates from './fetchInterestRates.js';
import _fetchInventoryListings from './fetchInventoryListings.js';
import _fetchMakes from './fetchMakes.js';
import _fetchModels from './fetchModels.js';
import _fetchPersonalizedListings from './fetchPersonalizedListings.js';
import _fetchRecentModelsByMake from './fetchRecentModelsByMake.js';
import _fetchRecommendedInventory from './fetchRecommendedInventory.js';
import _fetchReferenceData from './fetchReferenceData.js';
import _fetchValidateZip from './fetchValidateZip.js';
import _fetchSponsoredMake from './fetchSponsoredMake.js';
import _fetchSponsoredHero from './fetchSponsoredHero.js';
import _fetchPreviouslyViewedInventory from './fetchPreviouslyViewedInventory.js';
import _fetchYears from './fetchYears.js';
import _fetchPrivacyRequest from './fetchPrivacyRequest.js';
import _fetchMarketingValue from './fetchMarketingValue.js';
import _fetchPopularModels from './fetchPopularModels.js';
import _fetchKBBTradeIns from './fetchKBBTradeIns';
import _fetchSuperHero from './fetchSuperHero';
import _fetchPaymentCalculation from './fetchPaymentCalculation';
import _fetchCreditDecision from './fetchCreditDecision';
import _fetchCreditVerification from './fetchCreditVerification';
import _fetchEmailUsRequest from './fetchEmailUsRequest';
import _fetchMetaTags from './fetchMetaTags';

const fetcherWithMock = addMocks({
    _fetchHomePageBannerContent,
    _fetchArticles,
    _fetchConfigs,
    _fetchDataIsland,
    _fetchDealerLot,
    _fetchDealerLotSingle,
    _fetchDeepLinks,
    _fetchAemContent,
    _fetchIco,
    _fetchInterestRates,
    _fetchMakes,
    _fetchInventoryListings,
    _fetchModels,
    _fetchPersonalizedListings,
    _fetchRecentModelsByMake,
    _fetchRecommendedInventory,
    _fetchReferenceData,
    _fetchValidateZip,
    _fetchSponsoredMake,
    _fetchSponsoredHero,
    _fetchPreviouslyViewedInventory,
    _fetchYears,
    _fetchPrivacyRequest,
    _fetchMarketingValue,
    _fetchPopularModels,
    _fetchKBBTradeIns,
    _fetchSuperHero,
    _fetchPaymentCalculation,
    _fetchCreditDecision,
    _fetchCreditVerification,
    _fetchEmailUsRequest,
    _fetchMetaTags,
});

export const {
    fetchHomePageBannerContent,
    fetchArticles,
    fetchConfigs,
    fetchDataIsland,
    fetchDealerLot,
    fetchDealerLotSingle,
    fetchDeepLinks,
    fetchIco,
    fetchInterestRates,
    fetchInventoryListings,
    fetchMakes,
    fetchModels,
    fetchPersonalizedListings,
    fetchAemContent,
    fetchRecentModelsByMake,
    fetchRecommendedInventory,
    fetchReferenceData,
    fetchValidateZip,
    fetchSponsoredMake,
    fetchSponsoredHero,
    fetchPreviouslyViewedInventory,
    fetchYears,
    fetchPrivacyRequest,
    fetchMarketingValue,
    fetchPopularModels,
    fetchKBBTradeIns,
    fetchSuperHero,
    fetchPaymentCalculation,
    fetchCreditDecision,
    fetchCreditVerification,
    fetchEmailUsRequest,
    fetchMetaTags,
} = fetcherWithMock;
