/* eslint-disable no-console */
import React, { Suspense } from 'react';
import atcId from '@atc/bonnet-ctx-atc-id';
import withCtxMiddleware from '@bonnet/with-ctx-middleware';
import getConfig from '@bonnet/core/config';
import withCookies from '@bonnet/ctx-cookies';

import { toBoolean } from 'atc-js';
import { Provider as FeatureFlagsContextProvider } from 'reaxl-features';
import { DataIslandProvider } from 'reaxl-context';

import { AnalyticsProvider } from 'reaxl-analytics';

import {
    OptimizelyPageProvider,
    addOptimizelyDynamicConfig,
} from 'reaxl-optimizely';

import { AdLibrariesHeaders } from 'reaxl-ads';
import CacheKey from '../utilities/CacheKey';

import ConfigContext from '../contexts/ConfigContext';

import { analyticsProviderHandlers } from '../analytics';
import { withDataLayer } from '../analytics/withDataLayer';

import { fetchConfigs } from '../fetchers';

import featureConfig from '../constants/features';

import {
    withWalletConfigs,
    withUseFeatures,
    withCollectionsCSS,
} from '../context-middleware';

import {
    analyticsDuck,
    dealerLotDuck,
    dealerLotResultsDuck,
    interestRateDuck,
    inventoryResultsDuck,
    keywordSearchDuck,
    paymentsDuck,
    dealsDuck,
    referenceDuck,
    userDuck,
    currentPageNameDuck,
    pageDetailsDuck,
    recentSearchDuck,
    savedInventoryDuck,
} from '../ducks';

import {
    FooterContainer,
    HeaderContainer,
    OptimizelyAnalyticsListenerContainer,
    HeadContainer,
} from '../containers';

import { MyWalletContainer } from '../containers/LazyLoadCollectionsContainer';
import ThemeProvider from '../containers/ThemeProvider';

const EnhancedAnalyticsProvider = withDataLayer(AnalyticsProvider);

const renderAdLibrariesHeads = ({
    reqUrl,
    mvtFeatureParameters,
    configs,
}) => {

    const {
        ENABLE_AD_LIBRARIES_LOAD_FROM_SERVER,
    } = mvtFeatureParameters;

    const enableAdLibrariesLoadFromServer = !!ENABLE_AD_LIBRARIES_LOAD_FROM_SERVER;
    if (!enableAdLibrariesLoadFromServer) return null;

    const openWrapUrl = configs.site.enableOpenWrap && configs.site.openWrapUrl;
    const prebidPath = CacheKey.add('/resources/js/bundle/prebid.js', false);

    return (
        <AdLibrariesHeaders
            reqUrl={reqUrl}
            openWrapUrl={openWrapUrl}
            prebidPath={prebidPath}
        />
    );
};

const Collections = ({ Component, ...props }) => {
    const { configs, mvtParams, reqUrl, isServerSide, optimizelyDynamicConfig } = props;
    if (typeof window !== 'undefined' && mvtParams.DISABLE_AUDIOEYE) {
        //  eslint-disable-next-line
        window.$ae = '';
    }

    return (
        <OptimizelyPageProvider
            disabled={process.env.DISABLE_OPTIMIZELY}
            optimizelyDynamicConfig={optimizelyDynamicConfig}
        >
            <ConfigContext.Provider value={configs}>
                <FeatureFlagsContextProvider flags={{ ...mvtParams, config: featureConfig }}>
                    <EnhancedAnalyticsProvider
                        value={analyticsProviderHandlers}
                        isHomepage={props.location && props.location.pathname === '/'}
                    >
                        <DataIslandProvider>
                            <HeadContainer mvtParams={mvtParams} />
                            <ThemeProvider>
                                {isServerSide && renderAdLibrariesHeads({
                                    reqUrl,
                                    mvtFeatureParameters: mvtParams,
                                    configs,
                                })}
                                <HeaderContainer />
                                <Component {...props} />
                                {typeof window !== 'undefined' && (
                                    <Suspense fallback={null}>
                                        <MyWalletContainer />
                                    </Suspense>
                                )}
                                <FooterContainer />
                                <OptimizelyAnalyticsListenerContainer />
                            </ThemeProvider>
                        </DataIslandProvider>
                    </EnhancedAnalyticsProvider>
                </FeatureFlagsContextProvider>
            </ConfigContext.Provider>
        </OptimizelyPageProvider>
    );
};
Collections.getReduxConfiguration = ({ store }) => {
    store.addReducers([
        analyticsDuck,
        dealerLotDuck,
        dealerLotResultsDuck,
        interestRateDuck,
        inventoryResultsDuck,
        keywordSearchDuck,
        paymentsDuck,
        dealsDuck,
        referenceDuck,
        recentSearchDuck,
        savedInventoryDuck,
        userDuck,
        currentPageNameDuck,
        pageDetailsDuck,
    ]);
};

Collections.getInitialProps = async (ctx) => {
    const { publicRuntimeConfig } = getConfig();

    const {
        query: {
            forceFeatureFlags = '',
        },
        req,

    } = ctx;

    await withCtxMiddleware([
        withCollectionsCSS(),
        withCookies(),
        atcId(),
        addOptimizelyDynamicConfig({
            environment: publicRuntimeConfig.environment,
        }),
        withUseFeatures(),
        withWalletConfigs(),
    ], ctx);

    // Only on the server, we need to pass through the exp cookie
    const headers = {};

    if (req) {
        if (req.cookies.exp) {
            headers.cookie = `exp=${req.cookies.exp}`;
        }
    }

    const mvtParams = {
        LISTINGS_MOBILE_SUPPORT: true,
        my_wallet_myatc: true, // hardcoded flag since Optimizely was removed and this value was added to brand configs, which collections does not use
    };

    //  update mvtParams with forced flags from query string
    //  eslint-disable-next-line no-return-assign
    forceFeatureFlags.split('!').forEach(
        (flag) => {
            const split = flag.split(':');
            let key = flag;
            let value = 'true';
            if (split.length > 1) {
                const [flagName, flagValue] = split;
                key = flagName;
                value = toBoolean(flagValue);
            }
            mvtParams[key] = value;
        }
    );

    // replace this with service call
    const remoteConfigs = await fetchConfigs();
    const configs = {
        ...remoteConfigs,
        // perhaps we can load process.env here?
    };

    const reqUrl = req && `${req.protocol}://${req.get('host')}${req.originalUrl}`;

    const isServerSide = true;
    return {
        configs,
        mvtParams,
        reqUrl,
        isServerSide,
        isBot: ctx.data.isBot,
        optimizelyDynamicConfig: ctx.data.optimizelyDynamicConfig,
    };
};

Collections.displayName = '_APP_';

export default Collections;
