/* eslint no-unused-vars: "off" */
/* eslint-disable camelcase */
import React from 'react';
import { StaticFooter, FooterContentInjector } from 'reaxl-footer';
import { useAnalytics } from 'reaxl-analytics';

const FooterContainer = () => {

    const reaxlAnalytics = useAnalytics();

    const handleFooterClick = (event, data) => (
        reaxlAnalytics.sendClick('footerLinkClick', event, data)
    );

    return (
        <>
            <FooterContentInjector
                renderThirdParty
            />
            <StaticFooter
                onClick={handleFooterClick}
                enableOneTrustFooter
            />
        </>
    );
};

export default FooterContainer;
