/* global window */
import querystring from 'querystring';
import hasDom from 'has-dom';

// eslint-disable-next-line import/prefer-default-export
export function getPersonalizationClientEnv() {
    let env = 'qa';

    if (hasDom()) {
        const { location: { hostname, search } } = window;

        // TODO: I'd like to be able to use process.env.ENVIRONMENT...
        const isProd = ['www.autotrader.com', 'speedy.autotrader.com'].includes(hostname);

        if (isProd) {
            env = 'prod';
        } else {
            const query = querystring.parse(search.slice(1));

            if (query.useGigyaDev) {
                // useGigyaDev param is used for automation
                env = 'dev';
            }
        }
    }

    return { env };
}

export const calculateTotalInterest = ({ estTotalFinance, loanTerm, estInterestRate, monthlyPayment }) => {
    // Capture amortization as we figure out the total sales interest
    const amortization = [];
    let estTotalInterest = 0;

    let principal = estTotalFinance;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i <= loanTerm; i++) {
        const interest = estInterestRate * principal;
        estTotalInterest += interest;
        principal -= (monthlyPayment - interest);
        amortization.push({
            interest,
            principal,
            totalInterest: estTotalInterest,
        });
    }

    return {
        estTotalInterest,
        amortization,
    };
};

export const calculateLeasePayment = ({ newState }) => {
    const {
        msrp,
        salesTaxRate,
        loanTerm,
        apr,
        tradeInValue,
        amountOwed,
        downPayment,
        residualPercentage,
    } = newState;

    const estSalesTax = (msrp * salesTaxRate) / 100;
    const moneyFactor = apr / (loanTerm * 100);

    const grossCapitalCost = msrp + estSalesTax;
    const netTradeInAmount = tradeInValue - amountOwed;
    const estTotalFinance = grossCapitalCost - downPayment - netTradeInAmount;

    const residualValue = msrp * (residualPercentage / 100);
    const baseMonthlyPayment = (estTotalFinance - residualValue) / loanTerm;
    const monthlyInterest = (estTotalFinance + residualValue) * moneyFactor;

    const estMonthlyPayment = baseMonthlyPayment + monthlyInterest;
    const {
        estTotalInterest,
        amortization,
    } = calculateTotalInterest({ estTotalFinance, loanTerm, estInterestRate: (apr / 12) / 100, monthlyPayment: estMonthlyPayment });
    const estTotalLoan = Number(estTotalFinance) + Number(estTotalInterest);

    return {
        ...newState,
        leaseSummary: {
            estSalesTax: parseFloat(estSalesTax).toFixed(2),
            estMonthlyPayment: parseFloat(estMonthlyPayment).toFixed(2),
            estTotalFinance: parseFloat(estTotalFinance).toFixed(2),
            estTotalInterest: parseFloat(estTotalInterest).toFixed(2),
            estTotalLoan: parseFloat(estTotalLoan).toFixed(2),
            netTradeIn: parseFloat(netTradeInAmount).toFixed(2),
            amortization,
        },
    };
};
