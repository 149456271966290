import { fetchJSON } from '@bonnet/core/fetch';
import { CREDIT_VERIFICATION_TIMEOUT, CREDIT_VERIFICATION_RESET_TIMEOUT } from '../constants';

export default (data = {}) => {
    const options = {
        body: data,
        method: 'POST',
        circuitBreaker: {
            id: 'creditverification',
            timeout: CREDIT_VERIFICATION_TIMEOUT, // 15 seconds timeout to allow for lambda cold start
            resetTimeout: CREDIT_VERIFICATION_RESET_TIMEOUT, // reduce to 10 seconds so that user can resubmit after making changes
            fallback: {},
        },
        headers: {
            'Content-Type': 'text/plain',
        },
    };

    const endpoint = '/cars-for-sale/creditverification';

    return fetchJSON(endpoint, options);
};
