export default {
    sedan: {
        code: 'SEDAN',
        label: 'Sedans',
        link: '/sedan',
        displayName: 'Sedan',
        src: '//www.autotrader.com/content/dam/autotrader/homepage/styles/new_default_desk_style_sedan.png',
        src2x: '//www.autotrader.com/content/dam/autotrader/homepage/styles/new_default_desk_style_sedan@2x.png',
        type: 'bodyStyle',
    },
    truck: {
        code: 'TRUCKS',
        label: 'Trucks',
        link: '/truck',
        displayName: 'Truck',
        src: '//www.autotrader.com/content/dam/autotrader/homepage/styles/new_default_desk_style_truck.png',
        src2x: '//www.autotrader.com/content/dam/autotrader/homepage/styles/new_default_desk_style_truck@2x.png',
        type: 'bodyStyle',
    },
    'suv-crossover': {
        code: 'SUVCROSS',
        label: 'SUVs and Crossovers',
        link: '/suv-crossover',
        displayName: 'SUV/Crossover',
        src: '//www.autotrader.com/content/dam/autotrader/homepage/styles/new_default_desk_style_suv.png',
        src2x: '//www.autotrader.com/content/dam/autotrader/homepage/styles/new_default_desk_style_suv@2x.png',
        type: 'bodyStyle',
    },
    coupe: {
        code: 'COUPE',
        label: 'Coupes',
        link: '/coupe',
        displayName: 'Coupe',
        src: '//www.autotrader.com/content/dam/autotrader/homepage/styles/new_default_desk_style_coupe.png',
        src2x: '//www.autotrader.com/content/dam/autotrader/homepage/styles/new_default_desk_style_coupe@2x.png',
        type: 'bodyStyle',
    },
    hatchback: {
        code: 'HATCH',
        label: 'Hatchbacks',
        link: '/hatchback',
        displayName: 'Hatchback',
        src: '//www.autotrader.com/content/dam/autotrader/homepage/styles/new_default_desk_style_hatchback.png',
        src2x: '//www.autotrader.com/content/dam/autotrader/homepage/styles/new_default_desk_style_hatchback@2x.png',
        type: 'bodyStyle',
    },
    'van-minivan': {
        code: 'VANMV',
        label: 'Vans and Minivans',
        link: '/van-minivan',
        displayName: 'Van/Minivan',
        src: '//www.autotrader.com/content/dam/autotrader/homepage/styles/new_default_desk_style_van.png',
        src2x: '//www.autotrader.com/content/dam/autotrader/homepage/styles/new_default_desk_style_van@2x.png',
        type: 'bodyStyle',
    },
    convertible: {
        code: 'CONVERT',
        label: 'Convertibles',
        link: '/convertible',
        displayName: 'Convertible',
        src: '//www.autotrader.com/content/dam/autotrader/homepage/styles/new_default_desk_style_convertible.png',
        src2x: '//www.autotrader.com/content/dam/autotrader/homepage/styles/new_default_desk_style_convertible@2x.png',
        type: 'bodyStyle',
    },
    wagon: {
        code: 'WAGON',
        label: 'Wagons',
        link: '/wagon',
        displayName: 'Wagon',
        src: '//www.autotrader.com/content/dam/autotrader/homepage/styles/new_default_desk_style_wagon.png',
        src2x: '//www.autotrader.com/content/dam/autotrader/homepage/styles/new_default_desk_style_wagon@2x.png',
        type: 'bodyStyle',
    },
    'awd-4wd': {
        code: 'AWD4WD',
        label: 'AWD and 4WD Vehicles',
        link: '/awd-4wd',
        displayName: 'AWD/4WD',
        src: '//www.autotrader.com/content/dam/autotrader/homepage/styles/DefaultDesk_Style_AWD4WD.png',
        src2x: '',
        type: 'vehicleStyle',
    },
    luxury: {
        code: 'LUXURY',
        label: 'Luxury Vehicles',
        link: '/luxury',
        displayName: 'Luxury',
        src: '//www.autotrader.com/content/dam/autotrader/homepage/styles/DefaultDesk_Style_LUXURY.png',
        src2x: '',
        type: 'vehicleStyle',
    },
    'hybrid-electric': {
        code: 'HYBEL',
        label: 'Hybrid and Electric Vehicles',
        link: '/hybrid-electric',
        displayName: 'Hybrid/Electric',
        src: '//www.autotrader.com/content/dam/autotrader/homepage/styles/DefaultDesk_Style_HYBEL.png',
        src2x: '',
        type: 'vehicleStyle',
    },
    commercial: {
        code: 'COMMERCIAL',
        label: 'Commercial Vehicles',
        link: '/commercial',
        displayName: 'Commercial',
        src: '//www.autotrader.com/content/dam/autotrader/homepage/styles/DefaultDesk_Style_COMMERCIAL.png',
        src2x: '',
        type: 'vehicleStyle',
    },
};
