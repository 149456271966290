import { fetchJSON } from '@bonnet/core/fetch';

export default (value, fetchTotalPayment = false) => {
    const options = {
        circuitBreaker: {
            id: 'paymentCalculation',
            timeout: 5000,
            resetTimeout: 300000,
            fallback: null,
        },
    };

    options.headers = {
        'X-fwd-svc': 'atc',
    };

    const endpoint = `/rest/retailing/${fetchTotalPayment ? 'budget' : 'payments'}`;
    return fetchJSON(endpoint + '?' + value, options);
};
