/* eslint-disable no-console */
import CircuitBreaker from '../utilities/circuitbreaker/withCircuitBreaker';
import { fetchLoader } from '../utilities/cache';
import { onSuccess, onFailure } from '../utilities/circuitbreaker/handlers';

const eventName = 'FetchPopularModelsLoader';

const successHandler = (result) => {
    onSuccess(eventName, result);
};

const failureHandler = (err, latency, args) => {
    onFailure(eventName, err, latency, args);
};

export default async (query = {}) => {
    const baseUrl = '/collections/ccServices/rest/ccs/landing/popularmodels';
    const cacheKey = `${baseUrl}|${Object.values(query).join('|')}`;
    try {
        const popularModelsLoader = fetchLoader({ name: eventName, url: baseUrl, params: query });

        const { data } = await CircuitBreaker.withCircuitBreaker(
            eventName,
            popularModelsLoader.load,
            [cacheKey],
            {
                circuitBreakerOptions: {
                    timeout: process.env.CIRCUIT_BREAKER_TIMEOUT,
                },
                additionalOptions: {
                    onFallback: { data: [] },
                    key: cacheKey,
                    additionalEventHandlers: {
                        flipper: true,
                        events: [
                            { name: 'onSuccessPopularModelsLoader', event: 'success', handler: successHandler },
                            { name: 'onFailurePopularModelsLoader', event: 'failure', handler: failureHandler },
                        ],
                    },
                },
            }
        );
        return data.popularModels || [];
    } catch (err) {
        console.log(`${eventName}: Error fetching popular models`, err);
        return [];
    }
};
