/* eslint-disable no-console */
import { fetchJSON } from '@bonnet/core/fetch';
import getConfig from '@bonnet/core/config';

export default function fetchHomePageBannerContent() {
    const { publicRuntimeConfig: { aemHost } } = getConfig();
    return fetchJSON(`${aemHost}/content/dam/autotrader/collections/homePageBanner.json`, {
        circuitBreaker: {
            id: 'bannerContent',
            timeout: 1000,
            fallback: { showBanner: false },
        },
    });
}
