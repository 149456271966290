const addMocks = (fetchers) => {
    const newFetchers = {};

    Object.keys(fetchers).forEach((fetcher) => {
        const trimmedFetcherName = fetcher.substr(fetcher.indexOf('_') + 1, fetcher.length);
        let mocks = null;

        try {
            mocks = require(`./${trimmedFetcherName}/`); // eslint-disable-line
        } catch (e) {
            // Failed to find mocks!
        }

        if (mocks) {
            newFetchers[trimmedFetcherName] = (...args) => {
                // ex: mocks=fetchDeepLinks.hasData
                const mockQueries = args[1] ? args[1] : '';

                if (mockQueries) {
                    const queries = typeof mockQueries === 'string' ? [mockQueries] : mockQueries;
                    let addedMock = false;
                    const promise = queries.map((query) => {
                        // Return the mock object instead of the normal method
                        const match = query.match(new RegExp(`(^|\\|)${trimmedFetcherName}(\\.(.+?))?(\\||$)`));

                        if (match && !addedMock) {
                            const mock = mocks[match[3] || 'default'];
                            addedMock = true;

                            return new Promise((resolve, reject) => {
                                resolve(mock);
                            });
                        }

                        return null;
                    });

                    const filteredPromise = promise.filter((x) => x !== null);

                    return filteredPromise.length > 0 ? filteredPromise[0] : fetchers[fetcher](...args);
                }

                return fetchers[fetcher](...args);
            };

        } else {
            newFetchers[trimmedFetcherName] = fetchers[fetcher];
        }
    });

    return newFetchers;
};

export default addMocks;
