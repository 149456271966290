/* eslint-disable no-console */
import getConfig from '@bonnet/core/config';
import { decodeHTMLEntities } from 'atc-js';
import CircuitBreaker from '../utilities/circuitbreaker/withCircuitBreaker';
import { fetchLoader } from '../utilities/cache';
import { onSuccess, onFailure } from '../utilities/circuitbreaker/handlers';

const eventName = 'FetchAemContentLoader';

const successHandler = (result) => {
    onSuccess(eventName, result);
};

const failureHandler = (err, latency, args) => {
    onFailure(eventName, err, latency, args);
};

export default async (query = {}) => {
    if (query.path) {
        try {
            const { publicRuntimeConfig: { aemHost } } = getConfig();
            const baseUrl = `${aemHost}${query.path}`;
            const aemContentLoader = fetchLoader({ name: eventName, url: baseUrl, ssr: false });

            const { data } = await CircuitBreaker.withCircuitBreaker(
                eventName,
                aemContentLoader.load,
                [baseUrl],
                {
                    circuitBreakerOptions: {
                        timeout: process.env.CIRCUIT_BREAKER_TIMEOUT,
                    },
                    additionalOptions: {
                        onFallback: {},
                        key: baseUrl,
                        additionalEventHandlers: {
                            flipper: true,
                            events: [
                                { name: 'onSuccessAemContentLoader', event: 'success', handler: successHandler },
                                { name: 'onFailureAemContentLoader', event: 'failure', handler: failureHandler },
                            ],
                        },
                    },
                }
            );

            if (data && Object.keys(data).length > 0) {
                data.content = decodeHTMLEntities(data.content);
                return data;
            }
        } catch (err) {
            console.log(`${eventName}: Error fetching aem content`, err);
            return null;
        }
    }
    return null;
};
