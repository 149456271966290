/* eslint-disable no-console */
import { fetchJSON } from '@bonnet/core/fetch';

export default (query = {}) => fetchJSON('/collections/ccServices/rest/ccs/modelsyeargrouping', {
    query,
    circuitBreaker: {
        id: 'recentModelsByMake',
        timeout: process.env.CIRCUIT_BREAKER_TIMEOUT,
        fallback: {},
    },
});
