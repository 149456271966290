import { userDuckCreator } from 'axl-ducks';

import inventoryDuck from './inventoryDuck';
import savedInventoryDuck from './savedInventoryDuck';
import { getPersonalizationClientEnv } from './duckUtils';

const userDuck = userDuckCreator({
    inventoryDuck,
    savedInventoryDuck,
    ...getPersonalizationClientEnv(),
}).extend({
    reducer: (state, action, { types }) => {
        switch (action.type) {

            case types.SET_RECOMMENDED_INVENTORY: {
                return {
                    ...state,
                    fetchedRecommendedInventory: true,
                };
            }

            default:
                return state;
        }
    },
});

userDuck.buildInitialState = () => ({
    ...userDuck.initialState,
    fetchedRecommendedInventory: false,
});

export default userDuck;
