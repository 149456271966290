/* eslint-disable no-console */
import { fetchJSON } from '@bonnet/core/fetch';
import { fallbackInterestRates } from 'axl-config';

export default (query = {}) => fetchJSON('/collections/amazonaws/atc-interest-rates.awsacs.autotrader.com/rates.json', {
    query,
    circuitBreaker: {
        id: 'interestRates',
        timeout: process.env.CIRCUIT_BREAKER_TIMEOUT,
        fallback: fallbackInterestRates,
    },
}).catch(() => {});
