export const getYearRoutes = (currentYear) => {
    // minYear = 1981, maxYear = currentYear + 3
    const yearRoutes = Array((currentYear + 3) - 1980).fill().map((year, index) => 1981 + index).join('|');
    return yearRoutes;
};

export const getMMTRoutes = (vehicleMakes) => {
    const mmtRoutes = [];
    vehicleMakes.forEach((make, index) => {
        const { makeDescription } = make;
        if (/\s/.test(makeDescription)) {
            mmtRoutes.push(makeDescription.replace(/\s/g, '\\-'));
            mmtRoutes.push(makeDescription.replace(/\s/g, '\\+'));
        } else {
            mmtRoutes.push(makeDescription);
        }
    });
    return mmtRoutes.join('|').toLowerCase();
};
