import { useCookies } from 'react-cookie';
import { useDevice } from '@atc/react-device';
import { useSelector } from 'react-redux';
import { getActiveExperiments } from 'reaxl-optimizely';
import { getSignedInCookie } from '@consumer/auth-cognito';
import { getAkamaiZipCode } from 'atc-js';
import { inventoryDuck, userDuck } from '../ducks';
import { getPersonalizationClientEnv } from '../ducks/duckUtils';
import PersonalizationEngineModule from '../modules/PersonalizationEngineModule';

export default function useDataLayer() {
    const [cookies = {}] = useCookies(['MyConsumerSignedIn', 'pxa_id', 'pixall_abc', 'abc', 'pxa_realid']);
    const { device } = useDevice();
    const { zip: searchZipCode } = useSelector(userDuck.selectors.getLocation);
    const userZipCode = getAkamaiZipCode();
    const inventory = useSelector(inventoryDuck.selectors.getDuckState);
    const { env } = getPersonalizationClientEnv();
    const personalization = useSelector(PersonalizationEngineModule.duck.selectors.selectConsumerInsights);
    const preferences = useSelector(PersonalizationEngineModule.duck.selectors.selectPreferences);
    const consumerAdTargetsWithoutCAMPBias = useSelector(PersonalizationEngineModule.duck.selectors.selectConsumerAdTargetsWithoutCAMPBias);
    const consumerMarketingSegments = useSelector(PersonalizationEngineModule.duck.selectors.selectConsumerMarketingSegments);
    const consumerAdTargets = useSelector(PersonalizationEngineModule.duck.selectors.selectConsumerAdTargets);

    return {
        // hard coding for now, as it is only for homepage
        catalog: 'atc',
        application: {
            name: 'web',
            businessUnitName: 'Autotrader',
            environment: env === 'prod' ? 'production' : 'dev',
            version: 'Autotrader 1.0.0',
        },
        get user() {
            return {
                get isLoggedIn() {
                    return typeof window !== 'undefined' ? getSignedInCookie() : '0';
                },
                zipCode: userZipCode,
                ivtMoatFlag: '0',
                adBlockerState: 'off',
            };
        },
        search: {
            zipCode: searchZipCode,
        },
        page: {
            // bonnet page name
            pageName: useSelector((state) => state.currentPageName),
            experimentId: getActiveExperiments(),
            detailPageName: useSelector((state) => state.pageDetails.detailPageName),
            siteSection: useSelector((state) => state.pageDetails.siteSection),
            pageType: useSelector((state) => state.pageDetails.pageType),
            pixallId: cookies.pxa_id,
            pixallABC: cookies.pixall_abc || cookies.abc || cookies.pxa_realid || cookies.pxa_id,
            pixallPageInstanceId: useSelector((state) => state.pageDetails.pixallPageInstanceId),
        },
        consumerInsights: {
            ...personalization,
            consumerCampaignPreferencesAddedByRule: preferences?.topRankedVehicle?.addedByRule,
        },
        consumerAdTargets,
        consumerAdTargetsWithoutCAMPBias,
        consumerMarketingSegments,
        device,
        inventory,
    };
}
