import { fetchJSON } from '@bonnet/core/fetch';
import vehicleMakes from '../reference/vehicleMakes';

export default async (query) => fetchJSON('/collections/ccServices/rest/ccs/makes', {
    query,
    circuitBreaker: {
        id: 'makes',
        timeout: process.env.CIRCUIT_BREAKER_TIMEOUT,
        fallback: vehicleMakes,
    },
});
