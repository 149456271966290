import React from 'react';
import { Header } from 'reaxl-header';
import { useAnalytics } from 'reaxl-analytics';
import { header } from 'axl-config';

import { HeaderContentContainer } from '.';

const HeaderContainer = () => {
    const { sendClick, sendImpressions } = useAnalytics();

    const handleClick = (e, data) => {
        sendClick('headerGeneralEventHandler', e, data);
    };

    const onSponsorContentReady = (e, data) => {
        sendImpressions({
            name: 'headerGeneralEventHandler',
            data: {
                ...data,
                linkContext: 'PartnersImpression',
                originatedLocation: 'global-header',
            },
        });
    };

    return (
        <Header
            navItems={header.navItems}
            headerContent={<HeaderContentContainer handleClick={handleClick} />}
            onClick={handleClick}
            onSponsorContentReady={onSponsorContentReady}
        />
    );
};

export default HeaderContainer;
