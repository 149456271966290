import React from 'react';
import useDataLayer from './useDataLayer';

export const withDataLayer = (BaseComponent) => (props) => {
    const dataLayer = useDataLayer();

    const options = {
        disableGTM: false,
        disableGTMEvents: false,
    };

    return (
        <BaseComponent
            {...props}
            dataLayer={dataLayer}
            option={options}
        />
    );
};

export default withDataLayer;
