import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Head from '@bonnet/core/head';
import getConfig from '@bonnet/core/config';

const HeadContainer = React.memo((mvtParams) => {
    const { publicRuntimeConfig } = getConfig();

    const renderWebFontStyles = ({
        DISABLE_WEBFONTS,
    }) => {

        //  kill switch for webfonts
        if (DISABLE_WEBFONTS) {
            return null;
        }

        const s3Domain = publicRuntimeConfig.atcHost;
        const fontBaseUrlWoff = s3Domain ? `${s3Domain}content/dam/autotrader/fonts/` : 'https://fonts.gstatic.com/s/roboto/v20/';
        const fontBaseUrlTtf = s3Domain ? `${s3Domain}content/dam/autotrader/fonts/` : 'https://fonts.gstatic.com/s/roboto/v18/';
        const fontBaseUrlWoff900 = s3Domain ? `${s3Domain}content/dam/autotrader/fonts/` : 'https://fonts.gstatic.com/s/roboto/v30/';
        const fontBaseUrlTtf900 = s3Domain ? `${s3Domain}content/dam/autotrader/fonts/` : 'https://fonts.gstatic.com/s/roboto/v30/';

        // Font weight 400
        let fonts = `@font-face{font-family:Roboto;font-style:normal;font-weight:400;src:local('Roboto'),local('Roboto-Regular'),url(${fontBaseUrlWoff}KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2) format('woff2'),url(${fontBaseUrlWoff}KFOmCnqEu92Fr1Mu4mxM.woff) format('woff'),url(${fontBaseUrlTtf}TtfKFOmCnqEu92Fr1Mu4mxP.ttf) format('truetype');font-display:optional}`;

        // Font weight 700 Bold
        fonts += `@font-face{font-family:Roboto;font-style:normal;font-weight:700;src:local('Roboto Bold'),local('Roboto-Bold'),url(${fontBaseUrlWoff}KFOlCnqEu92Fr1MmWUlfBBc4AMP6lQ.woff2) format('woff2'),url(${fontBaseUrlWoff}KFOlCnqEu92Fr1MmWUlfBBc-.woff) format('woff'),url(${fontBaseUrlTtf}KFOlCnqEu92Fr1MmWUlfBBc9.ttf) format('truetype');font-display:optional}`;

        // Font weight 900 Bold
        fonts += `@font-face{font-family:Roboto;font-style:normal;font-weight:900;src:local(''),local(''),url(${fontBaseUrlWoff900}KFOlCnqEu92Fr1MmYUtfBBc4.woff2) format('woff2'),url(${fontBaseUrlWoff900}KFOlCnqEu92Fr1MmYUtfChc-.woff) format('woff'),url(${fontBaseUrlTtf900}KFOlCnqEu92Fr1MmYUtfChc9.ttf) format('truetype');font-display:optional}`;

        return (
            <>
                <style type="text/css">{fonts}</style>
            </>
        );
    };

    const renderPreconnects = () => (
        <>
            <link
                rel="preconnect"
                src="https://js-agent.newrelic.com"
            />
            <link
                rel="preconnect"
                src="https://bam.nr-data.net"
            />
            <link
                rel="preconnect"
                src="https://securepubads.g.doubleclick.net"
            />
            <link
                rel="preconnect"
                src="https://connect.facebook.net"
            />
            <link
                rel="preconnect"
                src="https://www.googletagmanager.com"
            />
            <link
                rel="preconnect"
                src="https://z.moatads.com"
            />
            <link
                rel="preconnect"
                src="https://smetrics.autotrader.com"
            />
            <link
                rel="preconnect"
                src="https://smetrics.autotrader.com"
            />
        </>
    );

    return (
        <Head>
            <html lang="en" />
            { renderWebFontStyles(mvtParams) }
            { mvtParams.launchOpt1 && renderPreconnects()}
        </Head>
    );
});

HeadContainer.propTypes = {
    mvtParams: PropTypes.object,
};

export default HeadContainer;
