import { inventoryDuckCreator } from 'axl-ducks';
import { createAction } from '@atc/modular-redux';

export default inventoryDuckCreator().extend({
    types: ['RESET'],
    reducer: (state, action, duck) => {
        switch (action.type) {
            case duck.types.RESET:
                return duck.initialState;

            default:
                return state;
        }
    },
    creators: (duck) => ({
        reset: createAction(duck.types.RESET),
    }),
});
