import { fetchJSON } from '@bonnet/core/fetch';

export default function fetchDealerLot(criteria) {
    return fetchJSON('/collections/lcServices/rest/lsc/dealerlot/multiple', {
        query: criteria,
        circuitBreaker: {
            id: 'dealerLot',
            timeout: process.env.CIRCUIT_BREAKER_TIMEOUT,
            fallback: {},
        },
    });
}
