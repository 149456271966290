import React from 'react';
import { ErrorPage as DefaultErrorComponent } from 'reaxl-organisms';
import HomePage500Error from './_hp500';

function ErrorPage({ statusCode, asPath }) {
    let ErrorComponent = <DefaultErrorComponent statusCode={statusCode} />;

    if (statusCode >= 500 && statusCode <= 599) {
        if (asPath && asPath === '/') {
            ErrorComponent = <HomePage500Error />;
        }
    }
    return ErrorComponent;
}

ErrorPage.displayName = 'ErrorPage';

ErrorPage.getInitialProps = ({ asPath, res, err }) => {
    /* eslint-disable no-nested-ternary */
    const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
    return {
        statusCode,
        asPath,
    };
};

export default ErrorPage;
