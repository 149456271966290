/* global document */
/* eslint no-unused-vars: "off" */
import React, {
    lazy,
    Suspense,
    useEffect,
    memo,
} from 'react';
import { mapDispatchToActionProp } from '@atc/modular-redux';
import { connect } from 'react-redux';
import hasDom from 'has-dom';
import {
    headerBadge,
} from 'axl-config';
import { useAnalytics } from 'reaxl-analytics';
import { ErrorBoundary } from 'reaxl-molecules';
import _get from 'lodash.get';

import { redirectExternal } from '../utilities';

import {
    dealerLotDuck,
    dealsDuck,
    paymentsDuck,
    recentSearchDuck,
    savedInventoryDuck,
    userDuck,
} from '../ducks';

const deferPromise = global.windowLoadPromise || Promise.resolve();

const AuthenticationHeader = lazy(
    () => deferPromise
        .then(() => import(
            /* webpackPrefetch: true */
            /* webpackChunkName: "authenticationHeader" */
            'reaxl-organisms/es/AuthenticationHeader'
        ))
);

const headerBadgeNavItems = Object.keys(headerBadge).reduce((acc, itemKey) => ({
    ...acc,
    [itemKey]: headerBadge[itemKey],
}), {});

const HeaderContentContainer = memo(({
    actions,
    saveListing = () => {},
    handleClick,
    isLoggedIn,
}) => {

    const initializeRecentSearches = async () => {
        const recentSearches = await actions.hydrateRecentSearches({ withCounts: true });
        return recentSearches;
    };

    useEffect(() => {
        initializeRecentSearches().then(() => actions.setRecentSearchesReady({ eLotQueryReady: true }));
        actions.hydrateRecentInventory();
    });

    const { sendClick } = useAnalytics();

    const handleQuickSaveItemClick = (event, { id }) => redirectExternal(`/cars-for-sale/vehicledetails.xhtml?listingId=${id}`);

    const handleQuickSaveConversion = (data) => {
        sendClick('quickSaveConversion', null, data);
    };

    const handleHeaderToggle = (data) => {
        sendClick('headerLittleGuyClick', null, data);
    };

    const handleQuickSaveItemToggle = (event, listing = {}, wasSaved) => {
        sendClick('inventorySave', event, { inventoryId: listing.id, wasSaved });
        saveListing({ event, listing, wasSaved });
    };

    const handleSaveInventoryUpdate = (data = {}) => {
        const {
            listing,
            alertByText,
            alertByEmail,
        } = data;
        // click event object is not supplied and is required by analytics to work properly
        const analyticsCustomEvent = new global.CustomEvent('ga-analytics:click', { detail: {} });

        if (alertByText) {
            sendClick('carAlertsClick', analyticsCustomEvent, { eventResult: 'checkbox::alert::text-car-vin', vehicle: listing });
        }
        if (alertByEmail) {
            sendClick('carAlertsClick', analyticsCustomEvent, { eventResult: 'checkbox::alert::email-car-vin', vehicle: listing });
        }
    };
    return hasDom() && (
        <Suspense fallback={null}>
            <ErrorBoundary>
                <AuthenticationHeader
                    dealsDuck={dealsDuck}
                    isLoggedIn={isLoggedIn}
                    userDuck={userDuck}
                    recentSearchDuck={recentSearchDuck}
                    savedInventoryDuck={savedInventoryDuck}
                    navItems={headerBadgeNavItems}
                    enableMyWallet
                    showPsxNotificationsCount
                    onQuickSaveItemClick={handleQuickSaveItemClick}
                    onQuickSaveConversion={handleQuickSaveConversion}
                    onQuickSaveItemToggle={handleQuickSaveItemToggle}
                    paymentsDuck={paymentsDuck}
                    onClick={handleClick}
                    onSaveInventoryUpdate={handleSaveInventoryUpdate}
                    onHeaderToggle={handleHeaderToggle}
                />
            </ErrorBoundary>
        </Suspense>
    );
});
const mapStateToProps = (state) => ({
    isLoggedIn: _get(state, 'auth.isLoggedIn', false),
});

const mapDispatchToProps = mapDispatchToActionProp({
    hydrateRecentInventory: userDuck.creators.hydrateRecentInventory,
    hydrateRecentSearches: recentSearchDuck.creators.hydrateRecentSearches,
    setRecentSearchesReady: dealerLotDuck.creators.setKeys,
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContentContainer);
