import { fetchJSON } from '@bonnet/core/fetch';

export default async ({
    id,
    fallback,
}) => {
    const { ALTEZZA_API_KEY } = process.env;
    const options = {
        circuitBreaker: {
            id: `${id}metaContent`,
            timeout: process.env.CIRCUIT_BREAKER_TIMEOUT,
            fallback,
        },
        headers: {
            'X-Api-Key': ALTEZZA_API_KEY,
        },
    };

    const endpoint = `/collections/altezza/api/v1/sites/atc/category?id=${id}`;

    let data;
    try {
        data = await fetchJSON(endpoint, options);

    } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Failed to fetch meta content from api', endpoint, error?.message);
    }

    return {
        title: data?.category?.title || data?.title,
        description: data?.category?.meta_description || data?.description,
        canonicalUrl: data?.category?.canonical_url || data?.canonicalUrl,
        h1: data?.category?.h1 || data?.h1,
        noIndex: data?.category?.no_index || data?.noIndex,
    };
};
