/* eslint-disable import/prefer-default-export,import/extensions */
import { lazy } from 'react';

/* These sections are lazy loaded */
const deferPromise = global.windowLoadPromise || Promise.resolve(true);
export const RelatedInventoryContainer = lazy(() => deferPromise.then(() => import(/* webpackChunkName: "LazyLoadCollectionsContainer" */ './styles/RelatedInventoryContainer')));

export const DealerLot = lazy(() => deferPromise.then(() => import(/* webpackChunkName: "LazyLoadCollectionsContainer" */ 'reaxl-organisms/es/DealerLot')));
export const DealerLotContainer = lazy(() => deferPromise.then(() => import(/* webpackChunkName: "LazyLoadCollectionsContainer" */ './DealerLotContainer')));

export const PreviouslyViewedContainer = lazy(() => deferPromise.then(() => import(/* webpackChunkName: "LazyLoadCollectionsContainer" */ './PreviouslyViewedContainer')));

export const BrowseByBudgetContainer = lazy(() => deferPromise.then(() => import(/* webpackChunkName: "lazymywallet" */ './hp/BrowseByBudgetContainer')));
export const MyWalletContainer = lazy(() => deferPromise.then(() => import(/* webpackChunkName: "lazymywallet" */ './MyWalletContainer')));
