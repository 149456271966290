import React from 'react';
import { ThemeProvider } from '@emotion/react';
import { atcTheme } from '@argo/themes';

export default (props) => (
    <ThemeProvider
        theme={atcTheme}
        {...props}
    />
);
