/* eslint-disable no-console */
import CircuitBreaker from '../utilities/circuitbreaker/withCircuitBreaker';
import { onSuccess, onFailure } from '../utilities/circuitbreaker/handlers';
import createResearchableAPILoader from './fetchResearchableAPI';
import WordpressEndpoints from '../utilities/researchable-api/researchableAPIEndpoint';

const eventName = 'FetchSuperHeroLoader';

const successHandler = (result) => {
    onSuccess(eventName, result);
};

const failureHandler = (err, latency, args) => {
    onFailure(eventName, err, latency, args);
};

export default (query = {}) => {
    try {
        const reqUrl = WordpressEndpoints.SuperHero.Autotrader;
        /* currently not using webapi-c endpoint due to long kbbsdk initialization - update coming soon */
        const superHeroLoader = createResearchableAPILoader({ name: eventName, ttl: 900 }); // cache for 15 min

        return CircuitBreaker.withCircuitBreaker(
            eventName,
            superHeroLoader.load,
            [`${reqUrl}`],
            {
                circuitBreakerOptions: {
                    timeout: process.env.CIRCUIT_BREAKER_TIMEOUT,
                },
                additionalOptions: {
                    onFallback: {},
                    key: `${reqUrl}`,
                    additionalEventHandlers: {
                        flipper: true,
                        events: [
                            { name: 'onSuccess', event: 'success', handler: successHandler },
                            { name: 'onFailure', event: 'failure', handler: failureHandler },
                        ],
                    },
                },
            }
        );
    } catch (err) {
        console.log(`${eventName}: Error fetching superhero`, err);
        return null;
    }
};
