/* eslint-disable */
import cache from './cache';
import request from './fetch';
import { getKeyWithPrimitives } from './utils';

export class Loader {
    constructor({
        name = 'default',
        functionToCache = request,
        ttl,
        cacheErrors = false,
        refreshCacheDelay = 1000,
        beforeGetFromCache = null,
        beforeGetData = null,
        beforePrecache = null,
        getKey = (...args) => {
            const hasNonPrimitives = args.some((arg) => (
                arg && (typeof arg === 'object' || Array.isArray(arg))
            ));

            const key = getKeyWithPrimitives(...args);

            if (hasNonPrimitives) {
                console.warn(`[Loader].  ${this.name} Loader accepts arguments of primitive type. Found arguments of type array/object for key ${key}.`);
            }

            return key;
        },
    }) {
        this.refreshCacheQueue = [];
        this.refreshCacheTimeoutHandle = null;
        this.name = name;
        this.functionToCache = functionToCache;
        this.ttl = ttl;
        this.cacheErrors = cacheErrors;
        this.refreshCacheDelay = refreshCacheDelay;
        this.getKey = getKey;
        this.beforeGetFromCache = beforeGetFromCache;
        this.beforeGetData = beforeGetData;
        this.beforePrecache = beforePrecache;
    }

  getCacheKey = (...args) => `${this.name}|${this.getKey(...args)}`

  getTimeToLive = () => this.ttl || cache.defaultTTL

  precache = async (...args) => {
      const timeToLive = this.getTimeToLive();
      const value = args[args.length - 1];
      const params = args.slice(0, args.length - 1);

      if (this.beforePrecache) { (await this.beforePrecache(...params)); }

      const cacheKey = this.getCacheKey(...params);
      await cache.setAsync(cacheKey, {
          ttl: timeToLive,
          expiration: Date.now() + timeToLive * 1000,
          value,
      }, timeToLive);
  }

  loadAndRefresh = async (...args) => {
      try {
          const cachedResult = await this.getCacheEntry(...args);

          if (cachedResult) {
              this.refreshCache(...args);
              return cachedResult.value;
          }
      } catch (e) {
      // Since getFromCache only errors out for situations where an error was cached, make sure to refresh the cache for those situations
          this.refreshCache(...args);
          throw e;
      }

      return this.getData(...args);
  }

  load = async (...args) => {
      const cacheEntry = await this.getCacheEntry(...args);

      if (cacheEntry) {
          return cacheEntry.value;
      }

      return this.getData(...args);
  }

  refreshCache = (...args) => {
      this.refreshCacheQueue.push({
          cacheKey: this.getCacheKey(...args),
          args,
      });
      if (!this.refreshCacheTimeoutHandle) {
          this.refreshCacheTimeoutHandle = setTimeout(this.processRefreshCacheQueue, this.refreshCacheDelay);
      }
  }

  processRefreshCacheQueue = async () => {
      while (this.refreshCacheQueue.length > 0) {
          const { cacheKey, args } = this.refreshCacheQueue[0];

          this.refreshCacheQueue = this.refreshCacheQueue.filter((entry) => entry.cacheKey !== cacheKey);
          try {
              // eslint-disable-next-line no-await-in-loop
              await this.getData(...args);
          } catch (e) {
              // Error getting data
              console.log('Error Refreshing Cache:', e);
          }
      }
      this.refreshCacheTimeoutHandle = clearTimeout(this.refreshCacheTimeoutHandle);
  }

  getFromCache = async (...args) => {
      const cacheEntry = await this.getCacheEntry(...args);
      return cacheEntry ? cacheEntry.value : null;
  }

  getCacheEntry = async (...args) => {
      if (this.beforeGetFromCache) { (await this.beforeGetFromCache(...args)); }
      const cacheKey = this.getCacheKey(...args);
      const timeToLive = this.getTimeToLive();
      // First try to get the value from cache
      let cacheEntry = null;
      try {
          cacheEntry = await cache.getAsync(cacheKey, timeToLive);
      } catch (err) {
          console.log('[Loader]. Error accessing cache', err);
      }

      if (cacheEntry) {
          if (cacheEntry.isException) {
              throw cacheEntry.value;
          } else {
              return cacheEntry;
          }
      }
      return null;
  }

  getData = async (...args) => {
      if (this.beforeGetData) { (await this.beforeGetData(...args)); }
      const cacheKey = this.getCacheKey(...args);
      const timeToLive = this.getTimeToLive();
      try {
          const value = await this.functionToCache(...args);
          await cache.setAsync(cacheKey, {
              ttl: timeToLive,
              expiration: Date.now() + timeToLive * 1000,
              value,
          }, timeToLive);
          return value;
      } catch (err) {
      // If the function call throws an error,
          if (this.cacheErrors) {
              await cache.setAsync(cacheKey, {
                  isException: true,
                  ttl: timeToLive,
                  expiration: Date.now() + timeToLive * 1000,
                  value: err,
              }, timeToLive);
          }

          throw err;
      }
  }
}

export default (config) => new Loader(config);
