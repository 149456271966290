/* eslint-disable no-console */
import NodeCache from 'node-cache';

const myCache = new NodeCache({ checkperiod: 86400 });

myCache.disableLocal = false;
myCache.defaultTTL = 60 * 60; // 1hr
myCache.name = Date.now();

myCache.getAsync = (cachekey, ttl) => {
    try {
        const value = myCache.get(cachekey);
        if (value) {
            return value;
        }
    } catch (err) {
        console.log(err);
    }
    return null;
};

myCache.setAsync = (cachekey, data, ttl) => {
    myCache.set(cachekey, data, ttl);
};

export default myCache;
