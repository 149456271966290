import { objectDuckCreator } from '@atc/modular-redux';

export default objectDuckCreator({
    namespace: 'DEALERLOT',
    storePath: 'dealerLot',
    store: 'content',
    initialState: {
        location: {},
        contextQuery: {},
        eLotQueryReady: false,
        recommendedInventoryQuery: {},
        fetchedDealerLot: false,
    },
});
