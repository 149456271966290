import { Pixall } from 'reaxl-analytics-handlers';

export async function shopCarsClick(metadata = {}, option) {
    const { data = {}, dataLayer } = metadata;

    const { pageType, code } = data;

    if (pageType === 'CONDITION') {
        Pixall.sendClickEvent({
            pageType: 'search',
            eventType: 'click',
            eventSource: 'button',
            label: `cta::srp::shop-${code}-cars`,
            hasListingType: false,
        }, dataLayer, option);
    }

    return {};
}

export async function shopCpoClick(metadata = {}, option) {
    const { dataLayer } = metadata;

    Pixall.sendClickEvent({
        pageType: 'search',
        eventType: 'click',
        eventSource: 'button',
        label: 'cta::srp::shop-certified-pre-owned-cpo',
        hasListingType: false,
    }, dataLayer, option);

    return {};
}

export async function additionalVehicleCategoriesClick(metadata = {}, option) {
    const { dataLayer } = metadata;

    Pixall.sendClickEvent({
        pageType: 'search',
        eventType: 'click',
        eventSource: 'button',
        label: 'cta::srp::additional-vehicle-categories',
        hasListingType: false,
    }, dataLayer, option);

    return {};
}

export default {
    shopCarsClick,
    shopCpoClick,
    additionalVehicleCategoriesClick,
};
