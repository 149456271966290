export default [
    { makeCode: 'ACURA', makeDescription: 'Acura' },
    { makeCode: 'ALFA', makeDescription: 'Alfa Romeo' },
    { makeCode: 'AMC', makeDescription: 'AMC' },
    { makeCode: 'AMGEN', makeDescription: 'HUMMER' },
    { makeCode: 'ASTON', makeDescription: 'Aston Martin' },
    { makeCode: 'AUDI', makeDescription: 'Audi' },
    { makeCode: 'BENTL', makeDescription: 'Bentley' },
    { makeCode: 'BMW', makeDescription: 'BMW' },
    { makeCode: 'BUGATTI', makeDescription: 'Bugatti' },
    { makeCode: 'BUICK', makeDescription: 'Buick' },
    { makeCode: 'CAD', makeDescription: 'Cadillac' },
    { makeCode: 'CHEV', makeDescription: 'Chevrolet' },
    { makeCode: 'CHRY', makeDescription: 'Chrysler' },
    { makeCode: 'DAEW', makeDescription: 'Daewoo' },
    { makeCode: 'DATSUN', makeDescription: 'Datsun' },
    { makeCode: 'DELOREAN', makeDescription: 'DeLorean' },
    { makeCode: 'DODGE', makeDescription: 'Dodge' },
    { makeCode: 'EAGLE', makeDescription: 'Eagle' },
    { makeCode: 'FER', makeDescription: 'Ferrari' },
    { makeCode: 'FIAT', makeDescription: 'FIAT' },
    { makeCode: 'FISK', makeDescription: 'Fisker' },
    { makeCode: 'FORD', makeDescription: 'Ford' },
    { makeCode: 'FREIGHT', makeDescription: 'Freightliner' },
    { makeCode: 'GENESIS', makeDescription: 'Genesis' },
    { makeCode: 'GEO', makeDescription: 'Geo' },
    { makeCode: 'GMC', makeDescription: 'GMC' },
    { makeCode: 'HONDA', makeDescription: 'Honda' },
    { makeCode: 'HYUND', makeDescription: 'Hyundai' },
    { makeCode: 'INFIN', makeDescription: 'INFINITI' },
    { makeCode: 'ISU', makeDescription: 'Isuzu' },
    { makeCode: 'JAG', makeDescription: 'Jaguar' },
    { makeCode: 'JEEP', makeDescription: 'Jeep' },
    { makeCode: 'KARMA', makeDescription: 'Karma' },
    { makeCode: 'KIA', makeDescription: 'Kia' },
    { makeCode: 'LAM', makeDescription: 'Lamborghini' },
    { makeCode: 'LEXUS', makeDescription: 'Lexus' },
    { makeCode: 'LINC', makeDescription: 'Lincoln' },
    { makeCode: 'LOTUS', makeDescription: 'Lotus' },
    { makeCode: 'MAS', makeDescription: 'Maserati' },
    { makeCode: 'MAYBACH', makeDescription: 'Maybach' },
    { makeCode: 'MAZDA', makeDescription: 'MAZDA' },
    { makeCode: 'MB', makeDescription: 'Mercedes-Benz' },
    { makeCode: 'MCLAREN', makeDescription: 'McLaren' },
    { makeCode: 'MERC', makeDescription: 'Mercury' },
    { makeCode: 'MINI', makeDescription: 'MINI' },
    { makeCode: 'MIT', makeDescription: 'Mitsubishi' },
    { makeCode: 'NISSAN', makeDescription: 'Nissan' },
    { makeCode: 'OLDS', makeDescription: 'Oldsmobile' },
    { makeCode: 'PLYM', makeDescription: 'Plymouth' },
    { makeCode: 'PONT', makeDescription: 'Pontiac' },
    { makeCode: 'POR', makeDescription: 'Porsche' },
    { makeCode: 'RAM', makeDescription: 'RAM' },
    { makeCode: 'ROV', makeDescription: 'Land Rover' },
    { makeCode: 'RR', makeDescription: 'Rolls-Royce' },
    { makeCode: 'SAAB', makeDescription: 'Saab' },
    { makeCode: 'SATURN', makeDescription: 'Saturn' },
    { makeCode: 'SCION', makeDescription: 'Scion' },
    { makeCode: 'SMART', makeDescription: 'smart' },
    { makeCode: 'SRT', makeDescription: 'SRT' },
    { makeCode: 'SUB', makeDescription: 'Subaru' },
    { makeCode: 'SUZUKI', makeDescription: 'Suzuki' },
    { makeCode: 'TESLA', makeDescription: 'Tesla' },
    { makeCode: 'TOYOTA', makeDescription: 'Toyota' },
    { makeCode: 'VOLKS', makeDescription: 'Volkswagen' },
    { makeCode: 'VOLVO', makeDescription: 'Volvo' },
    { makeCode: 'YUGO', makeDescription: 'Yugo' },
    { makeCode: 'POLESTAR', makeDescription: 'Polestar' },
];
