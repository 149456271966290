/* eslint-disable no-console */
import { getMarket } from '@atc/bonnet-reference';
import { setUserZipCookie } from '../utilities';

export default async (event, zip = null) => {
    const { success, error = null } = await getMarket(zip);

    // if zip is legit, set ATC_USER_ZIP cookie so it can persist across apps
    if (success) {
        setUserZipCookie(zip);
    }

    // Ex: [BONNET REFERENCE] [getMarket] cannot find market data for zip: 11111, city: undefined, state: undefined
    // In the case of an error do not block the user from continuing search to the SRP
    if (error) {
        throw new Error(error);
    }

    return success; // if success is false, the catch block is invoked
};
