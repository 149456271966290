import _get from 'lodash.get';
import { getOptimizelyInstance } from 'reaxl-optimizely';

// convert string from snake_case to camelCase
const snakeToCamel = (str = '') => str.replace(
    /([-_][a-z])/g,
    (group) => group.toUpperCase()
        .replace('-', '')
        .replace('_', '')
);

// duplicates snakecase keyed properties with camelCase keyed properties
const duplicatePropertiesWithCamelCase = (acc, [key, value]) => {
    acc[key] = value;

    if (key.includes('-') || key.includes('_')) {
        acc[snakeToCamel(key)] = value;
    }

    return acc;
};

// adds useFeatures functionality to the ctx object
export default function withUseFeatures() {
    return async (ctx) => {

        // looks up singular feature key
        ctx.useFeature = (key) => {

            // currently requires _app to have returned featureFlags and optimizelyDynamicConfig from getInitialProps to work
            const { featureFlags = {}, optimizelyDynamicConfig = {} } = _get(ctx, 'appProps', {});
            const { user = {}, forcedFeatures: forcedOptimizelyFeatures = {} } = optimizelyDynamicConfig;

            const { launchControl = {}, config = {} } = featureFlags;
            const optimizely = getOptimizelyInstance();
            const { featuresMap: optimizelyFeatures = {} } = optimizely.getOptimizelyConfig();

            // default feature to NOT enabled
            let isFeatureEnabled = false;
            let featureVariables = {};

            if (launchControl[key]) {

                // set feature enabled to launch control value if it exists
                isFeatureEnabled = launchControl[key];

            } else if (forcedOptimizelyFeatures[key] || (optimizely && optimizelyFeatures[key] && optimizely.isFeatureEnabled(key, user.id, user.attributes))) {

                // if an optimizely feature exists and is enabled we set it to true
                isFeatureEnabled = true;
                featureVariables = {
                    ...optimizely.getAllFeatureVariables(key, user.id, user.attributes),
                    ...forcedOptimizelyFeatures[key],
                };

            } else if (config[key]) {

                const { enabled: configEnabled, variables: configVariables = {} } = config[key];
                isFeatureEnabled = configEnabled;
                featureVariables = configVariables;
            }

            // duplicate snake_case property keys
            featureVariables = Object.entries(featureVariables).reduce(duplicatePropertiesWithCamelCase, {});

            return [isFeatureEnabled, featureVariables];
        };

        ctx.useFeatures = (keys = []) => {

            const features = [].concat(keys).reduce((acc, key) => ({
                ...acc,
                [key]: ctx.useFeature(key),
            }), {});

            // duplicate snake_case property keys
            return Object.entries(features).reduce(duplicatePropertiesWithCamelCase, {});
        };
    };
}
