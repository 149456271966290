/* eslint-disable no-console*/
import { log } from '../newrelic/logger';

export const onSuccess = (eventName, result) => {
    try {
        log({ eventName, requestParams: result?.config?.params, status: result?.status, type: 'response' });
    } catch (error) {
        console.log(`${eventName} CircuitBreaker: error processing onSuccess: ${error}`);
    }
};

// CircuitBreaker err comes back with fields:
// message:
//    "Request failed with status code 400"
//    "Request failed with status code 500"
//    "Timed out after 1000ms"
// config:params or args[1].params (request params info)
// code: "ETIMEDOUT" (for timeout case only)
export const onFailure = (eventName, err, latency, args) => {
    try {
        const messageRegex = /(status code|Timed out).*\s(\d{3,4})/g;
        const matches = [];
        if (err && err.message) {
            err.message.replace(messageRegex, (match, message, value) => {
                matches.push({
                    message,
                    value,
                });
            });
        }
        let status;
        let params;
        const message = (matches && matches.length > 0) ? matches[0].message : undefined;
        const type = (message && message === 'Timed out') ? `error:${message}` : 'error';
        if (message && message === 'status code') {
            status = matches[0].value;
        } else if (message === 'Timed out') {
            status = err?.code;
        }
        if (err && err.config) {
            params = err.config.params;
        } else if (args && args.length >= 2) {
            params = args[1].params;
        }
        log({ eventName, requestParams: params, status, type });
    } catch (error) {
        console.log(`${eventName} CircuitBreaker: error processing onFailure: ${error}`);
    }
};
