/* eslint-disable no-console */
import { fetchJSON } from '@bonnet/core/fetch';

const usedCarsRegex = /used\+cars\/(.+)/;
const dashesRegex = /\+(~\+)?/g;

export default async (query = {}) => {
    const data = await fetchJSON('/collections/ccServices/rest/ccs/deeplinks', {
        query,
        circuitBreaker: {
            id: 'deepLinks',
            timeout: process.env.CIRCUIT_BREAKER_TIMEOUT,
            fallback: [],
        },
    });

    if (!data || data.length < 1) {
        return data;
    }

    let updatedLinks;
    let updatedUrl;

    return data.map((deepLink) => {
        if (deepLink.listingType === 'NEW') {
            deepLink.links.sort((a, b) => {
                const valueA = a.value.toLowerCase();
                const valueB = b.value.toLowerCase();

                if (valueA < valueB) {
                    return -1;
                }
                if (valueA > valueB) {
                    return 1;
                }
                return 0;
            });
        }

        updatedLinks = deepLink.links.map((link) => {
            updatedUrl = link.url.replace(usedCarsRegex, '$1')
                .replace(dashesRegex, '-');

            return {
                ...link,
                url: updatedUrl,
            };
        });

        return {
            ...deepLink,
            links: updatedLinks,
        };
    });
};
