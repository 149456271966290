import { paymentsDuckCreator } from 'axl-ducks';
import querystring from 'querystring';
import hasDom from 'has-dom';
import {
    WalletStorageService,
    WalletStorageFetcher,
    ownerDetailsFetcher,
} from 'reaxl-wallet';

import { fetchPaymentCalculation as paymentFetcher } from '../fetchers';

function getPersonalizationClientEnv() {
    let env = 'qa';

    if (hasDom()) {
        const { location: { hostname, search } } = window;

        const isProd = ['www.autotrader.com', 'speedy.autotrader.com'].includes(hostname);

        if (isProd) {
            env = 'prod';
        } else {
            const query = querystring.parse(search.slice(1));

            if (query.useGigyaDev) {
                // useGigyaDev param is used for automation
                env = 'dev';
            }
        }
    }

    return { env };
}

const walletStorageService = new WalletStorageService({
    fetcher: WalletStorageFetcher,
    environment: getPersonalizationClientEnv()?.env,
});

const paymentsDuck = paymentsDuckCreator({
    store: 'payments',
    paymentFetcher,
    walletStorageService,
    ownerDetailsFetcher: ownerDetailsFetcher({
        hostname: '/collections/lcServices',
    }),
});

export default paymentsDuck;
