import { Pixall } from 'reaxl-analytics-handlers';

export default async function mainSeoDeepLinkClick(metadata = {}, option) {
    const { data = {}, dataLayer } = metadata;

    const { type, maxPrice, cmp } = data;

    Pixall.sendClickEvent({
        pageType: 'search',
        eventType: 'click',
        eventSource: 'link',
        label: `text-link::srp::${cmp === 'car_sale_owner' ? 'cars-for-sale-by-owner' : `${type}-cars-for-sale`}-under-${maxPrice}`,
        hasListingType: false,
    }, dataLayer, option);

    return {};
}
