import { fetchJSON } from '@bonnet/core/fetch';

export default async (query) => fetchJSON('/collections/ccServices/rest/ccs/landing/hero', {
    query,
    circuitBreaker: {
        id: 'sponsoredHero',
        timeout: process.env.CIRCUIT_BREAKER_TIMEOUT,
        fallback: {},
    },
});
