/* eslint-disable no-console */
import { fetchJSON } from '@bonnet/core/fetch';
import { trimString } from 'atc-js';

export default (query = {}) => fetchJSON('/collections/ccServices/rest/ccs/newsandreviews', {
    query,
    circuitBreaker: {
        id: 'articles',
        timeout: process.env.CIRCUIT_BREAKER_TIMEOUT,
        fallback: [],
    },
}).then((json) => {
    if (json) {
        return json.map((article, index) => {
            const { text, ...rest } = article;
            return {
                text: index === 0 && text ? trimString(text, 150) : '',
                ...rest,
            };
        });
    }
    return false;
});
