import { createLoader, request } from '../utilities/cache';

const getRequestPathParameters = (reqPath) => {
    // Researchable API paths commonly utilize the following format:
    // "api/[endpoint_group]/[endpoint_name]/[additional_path_parameters]?[query_parameters]"
    const reqPathElements = reqPath.split(/[/?]/).filter((el) => el);

    return {
        endpointGroup: reqPathElements[1],
        endpointName: reqPathElements[2],
    };
};

const fetchResearchableAPI = async (reqPath, mapper, maxResults) => {
    if (!reqPath || typeof reqPath !== 'string') {
        throw TypeError('The first parameter "reqPath" of type "string" is required.');
    }

    if (mapper && typeof mapper !== 'function') {
        throw TypeError('The second parameter "mapper" must be of type "function".');
    }

    if (maxResults && typeof maxResults !== 'number') {
        throw TypeError('The third parameter "maxResults" must be of type "number".');
    }

    const RESEARCHABLE_BASE_URL = process.env.RESEARCHABLE_API_BASE_URL || '';
    const RESEARCHABLE_API_KEY = process.env.RESEARCHABLE_API_KEY || '';
    let reqUrl;

    try {
        const { endpointGroup, endpointName } = getRequestPathParameters(reqPath);
        reqUrl = `${RESEARCHABLE_BASE_URL}/${reqPath}`;

        // By default, with the exception of the 'vehicles' endpoint, return all results for both 'reference data' and 'motorcycle' endpoint groups,
        // as well as the 'active campaign' personalization endpoint
        if (maxResults || endpointName === 'adbook-active-campaigns' || (endpointName !== 'vehicles' && (endpointGroup === 'reference' || endpointGroup === 'motorcycles'))) {
            let responseList = [];
            let nextUrl = reqUrl;
            while (nextUrl && (!maxResults || responseList.length < maxResults)) {

                // eslint-disable-next-line no-await-in-loop
                const response = await request(nextUrl, { headers: { 'x-api-key': RESEARCHABLE_API_KEY } });

                if (!response.data?.items) {
                    // In the scenario where no 'items' attribute exists, return response immediately.
                    // Endpoint data is limited and not designed to be paginated.
                    // eslint-disable-next-line no-await-in-loop
                    return mapper ? await mapper(response.data) : response.data;
                }

                responseList.push(...response.data.items);
                nextUrl = response.next;
            }

            if (mapper) {
                responseList = await mapper(responseList);
            }
            return maxResults ? responseList.slice(0, maxResults) : responseList;
        }

        // For all other endpoints, return results based on default limits provided by the API
        const response = await request(reqUrl, { headers: { 'x-api-key': RESEARCHABLE_API_KEY } });
        const result = response && (response.data.items || response.data);
        return mapper ? await mapper(result) : result;
    } catch (err) {
        // eslint-disable-next-line no-console
        console.warn('Failed to load', reqUrl, err);
        // Throw the error to ensure that we do not cache the result
        throw err;
    }
};

export default (props) => createLoader({
    functionToCache: fetchResearchableAPI,
    ...props,
});
