import { Cookies } from 'atc-js';

export default function setUserZipCookie(zip) {
    const cookies = new Cookies();

    return cookies.set('ATC_USER_ZIP', zip, {
        path: '/',
        maxAge: 63072000,
    });
}
