import { fetchJSON } from '@bonnet/core/fetch';
import credentials from './credentials';

export default function fetchPreviouslyViewedInventory(listingIds) {
    let listingIdsQuery = '';
    listingIds.forEach((item) => { listingIdsQuery += `&listingId=${item.id}`; });
    return fetchJSON(`/collections/lcServices/rest/lsc/listing?collapseOwners=true&collapseFilters=true&numRecords=16${listingIdsQuery}`, {
        credentials: credentials.fetchPreviouslyViewedInventory,
        circuitBreaker: {
            id: 'previouslyInventory',
            timeout: process.env.CIRCUIT_BREAKER_TIMEOUT,
            fallback: {},
        },
    });
}
