import { dealsDuckCreator } from 'axl-ducks';
import hasDom from 'has-dom';
import {
    WalletStorageFetcher,
    WalletStorageService,
    ownerDetailsFetcher,
} from 'reaxl-wallet';

import { fetchPaymentCalculation as paymentFetcher } from '../fetchers';

function getEnv() {
    let env = 'qa';
    if (hasDom()) {
        // eslint-disable-next-line
        if (['www.autotrader.com', 'speedy.autotrader.com', 'www.kbb.com'].includes(window?.location?.hostname)) {
            env = 'prod';
        }
    }

    return env;
}

const walletStorageService = new WalletStorageService({
    fetcher: WalletStorageFetcher,
    environment: getEnv(),
});

const dealsDuck = dealsDuckCreator({
    store: 'deals',
    paymentFetcher,
    walletStorageService,
    ownerDetailsFetcher: ownerDetailsFetcher(),
});

export default dealsDuck;
