// TODO: We don't manage the implementation for those wallet features, once wallet team cleanup optimizely for it, we can safely remove those config in this file.
// NOTE: Used for brand related configs only
// For more examples see: https://ghe.coxautoinc.com/Autotrader/find-car/tree/master/src/features
const featureConfig = {
    active_deals_my_wallet: {
        enabled: true,
        variables: {
            contact_email: true,
            contact_sms: false,
            contact_phone: true,
        },
    },
    my_wallet: {
        enabled: true,
        variables: {
            show_articles: true,
            show_ico: true,
            show_psx: true,
            enable_deals: true,
        },
    },
    wallet_manager: {
        enabled: true,
        variables: {
            hydrate_wallet: true,
            update_payments_info: true,
        },
    },
    wallet_payment_option: {
        enabled: true,
        variables: {
            use_lowest_payment_option: true,
        },
    },
};

export default featureConfig;
